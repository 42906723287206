<template>
  <base-material-card
    icon="mdi-receipt"
    :title="$t('labels.overview')"
    class="px-5 py-3"
  >
    <v-data-table
      :headers="headers"
      :items="invoices"
      class="elevation-0"
      :loading="loading"
    >
      <template v-slot:item="props">
        <tr>
          <td>{{ props.item.periodStart }} - {{ props.item.periodEnd }}</td>
          <td>{{ props.item.legalEntity.name }}</td>
          <td>{{ $asCurrency(props.item.amountNet) }}</td>
          <td>{{ $asCurrency(props.item.vatAmount) }}</td>
          <td>{{ props.item.paid ? "Betaald" : "Te betalen" }}</td>
          <td>
            <a style="color: rgba(0, 0, 0, 0)" :href="props.item.pdfUrl"
              ><v-icon>mdi-pdf-box</v-icon></a
            >
          </td>
          <td>
            <a
              style="color: rgba(0, 0, 0, 0)"
              v-if="props.item.csvUrl"
              :href="props.item.csvUrl"
              ><v-icon>mdi-file-delimited-outline</v-icon></a
            >
          </td>
        </tr>
      </template>
    </v-data-table>
  </base-material-card>
</template>


<script>
export default {
  data() {
    return {
      invoices: [],
      loading: false,
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t("labels.period"), value: "documentDate" },
        { text: this.$t("labels.legalEntity"), value: "legalEntity.name" },
        { text: this.$t("labels.netAmount"), value: "amountNet" },
        { text: this.$t("labels.vat"), value: "vatAmount" },
        { text: this.$t("labels.status"), value: "paid" },
        { text: this.$t("labels.pdf"), value: "id", sortable: false },
        { text: this.$t("labels.csv"), value: "id", sortable: false },
      ];
    },
  },

  async mounted() {
    this.loading = true;
    this.invoices = await this.$api.getInvoices();
    this.loading = false;
  },

  methods: {
    openUrl(url) {
      window.open(url);
    },
  },
};
</script>